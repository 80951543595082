<template>
  <div>
    <div class="about">
      <v-card elevation="0" color="#AC8250" class="white--text pa-6 text-body-1">
        <h2 class="text-h5 mb-6">{{ $t('AppBar.SubMenu.ScPurpose') }}</h2>
        <p v-html="$t('Page.Use.useDesc')" class="text-body-1"></p>
        <br/>
        <h2 class="text-h5 mb-6">{{ $t('Page.Use.Value') }}</h2>
        <p v-html="$t('Page.Use.ValueDesc')" class="text-body-1"></p>
      </v-card>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../components/Footer.vue'

export default {
  components: {
    Footer
  },
  mounted(){
  }
}
</script>

<style lang="scss">
  .about {
    min-height: calc(100vh - 289px);
    padding: 80px 120px;
    background: #F8F4F0;
  }

  @media screen and (max-width: 960px) {
    .about {
      padding: 30px 24px;
    }
  }
</style>
  